import React from "react";

class TosPage extends React.Component {
  render() {
    return (
      <div id="scrollAllow">
        Impressum
        <br />
        <br />
        Lucas Goldner
        <br />
        <br />
        Heerstraße 13
        <br />
        71711 Murr
        <br />
        +49 15223112600
        <br />
        <br />
        <br />
        Privacy Declaration
        <br />
        <br />
        Effective date: February 18, 2023
        <br />
        <br />
        Lucas Goldner ("us", "we", or "our") operates the app / website
        (hereinafter referred to as the "Service").
        <br />
        <br />
        This page informs you of our policies regarding the collection, use and
        disclosure of personal data <br />
        when you use our Service and the choices you have associated with that{" "}
        <br />
        data.
        <br />
        <br />
        We use your data to provide and improve the Service. By using the
        Service, you agree to the <br />
        collection and use of information in accordance with this policy.
        <br />
        Unless otherwise defined in this Privacy Policy, the terms used in this
        Privacy Policy have the <br />
        same meanings as in our Terms and Conditions, accessible from the app /
        website app
        <br />
        <br />
        Definitions <br />
        -----------
        <br />
        <br />
        * Service
        <br />
        <br />
        Service is the app / website operated by Lucas Goldner
        <br />
        <br />
        * Personal Data
        <br />
        <br />
        Personal Data means data about a living individual who can be identified
        from those data (or from <br />
        those and other information either in our possession or likely to come{" "}
        <br />
        into our possession).
        <br />
        <br />
        * Usage Data
        <br />
        <br />
        Usage Data is data collected automatically either generated by the use
        of the Service or from the <br />
        Service infrastructure itself (for example, the
        <br />
        duration of a page visit).
        <br />
        <br />
        * Cookies
        <br />
        <br />
        Cookies are small files stored on your device (computer or mobile
        device).
        <br />
        <br />
        * Data Controller
        <br />
        <br />
        Data Controller means the natural or legal person who (either alone or
        jointly or in common with <br />
        other persons) determines the purposes for which and the manner in{" "}
        <br />
        which any personal information are, or are to be, processed.
        <br />
        <br />
        For the purpose of this Privacy Policy, we are a Data Controller of your
        personal Data.
        <br />
        <br />
        * Data Processors (or Service Providers)
        <br />
        <br />
        Data Processor (or Service Provider) means any natural or legal person
        who processes the data <br />
        on behalf of the Data Controller.
        <br />
        <br />
        We may use the services of various Service Providers in order to process
        our data more <br />
        effectively.
        <br />
        <br />
        * Data Subject (or User)
        <br />
        <br />
        Data Subject is any living individual who is using our Service and is
        the subject of Personal Data.
        <br />
        <br />
        Information Collection and Use <br />
        ------------------------------
        <br />
        <br />
        We collect several different types of information for various purposes
        to provide and improve our <br />
        Service to you.
        <br />
        <br />
        Types of Data Collected <br />
        ~~~~~~~~~~~~~~~~~~~~~~~
        <br />
        <br />
        Personal Data <br />
        *************
        <br />
        <br />
        While using our Service, we may ask you to provide us with certain
        personally identifiable <br />
        information that can be used to contact or identify you ("Personal
        Data"). Personally <br />
        identifiable information may include, but is not limited to:
        <br />
        <br />
        * Email address
        <br />
        * First name and last name
        <br />
        * Cookies and Usage Data
        <br />
        Usage Data <br />
        **********
        <br />
        <br />
        We may also collect information on how the Service is accessed and used
        ("Usage Data"). This <br />
        Usage Data may include information such as your computer's Internet{" "}
        <br />
        Protocol address (e.g. IP address), browser type, browser version, the
        pages of our Service that <br />
        you visit, the time and date of your visit, the time spent on those
        pages, <br />
        unique device identifiers and other diagnostic data.
        <br />
        <br />
        Tracking & Cookies Data <br />
        ************
        <br />
        <br />
        We use cookies and similar tracking technologies to track the activity
        on our Service and we hold <br />
        certain information.
        <br />
        <br />
        Cookies are files with a small amount of data which may include an
        anonymous unique identifier. <br />
        Cookies are sent to your browser from a website and stored on your{" "}
        <br />
        device. Other tracking technologies are also used such as beacons, tags
        and scripts to collect <br />
        and track information and to improve and analyze our Service.
        <br />
        <br />
        You can instruct your browser to refuse all cookies or to indicate when
        a cookie is being sent. <br />
        However, if you do not accept cookies, you may not be able to use some{" "}
        <br />
        portions of our Service.
        <br />
        <br />
        Examples of Cookies we use:
        <br />
        <br />
        * Session Cookies. We use Session Cookies to operate our Service.
        <br />
        <br />
        * Preference Cookies. We use Preference Cookies to remember your
        preferences and various <br />
        settings. Security Cookies. We use Security Cookies for security
        purposes.
        <br />
        <br />
        Use of Data <br />
        -----------
        <br />
        <br />
        Swoboda uses the collected data for various purposes:
        <br />
        <br />
        * To provide and maintain our Service
        <br />
        * To notify you about changes to our Service
        <br />
        * To allow you to participate in interactive features of our Service
        when you choose to do so
        <br />
        * To provide customer support
        <br />
        * To gather analysis or valuable information so that we can improve our
        Service
        <br />
        * To monitor the usage of our Service
        <br />
        * To detect, prevent and address technical issues
        <br />
        <br />
        Legal Basis for Processing Personal Data under the General Data
        Protection Regulation (GDPR) <br />
        --------------------------------------------------------------------------------------------
        <br />
        If you are from the European Economic Area (EEA), Swoboda legal basis
        for collecting and using <br />
        the personal information described in this Privacy Policy depends on the{" "}
        <br />
        Personal Data we collect and the specific context in which we collect
        it.
        <br />
        <br />
        Lucas Goldner may process your Personal Data because:
        <br />
        <br />
        * We need to perform a contract with you
        <br />
        * You have given us permission to do so
        <br />
        * The processing is in our legitimate interests and it is not overridden
        by your rights
        <br />
        * For payment processing purposes
        <br />
        * To comply with the law
        <br />
        <br />
        Retention of Data <br />
        -----------------
        <br />
        <br />
        Lucas Goldner will retain your Personal Data only for as long as is
        necessary for the <br />
        purposes set out in this Privacy Policy. We will retain and use your
        Personal Data to the extent <br />
        necessary to comply with our legal obligations (for example, if we are
        required to retain your data <br />
        to comply with applicable laws), resolve disputes and enforce our legal{" "}
        <br />
        agreements and policies.
        <br />
        <br />
        Lucas Goldner will also retain Usage Data for internal analysis
        purposes. Usage Data is <br />
        generally retained for a shorter period of time, except when this data
        is used to strengthen <br />
        the security or to improve the functionality of our Service, or we are
        legally obligated to retain this <br />
        data for longer periods.
        <br />
        <br />
        Transfer of Data <br />
        ----------------
        <br />
        <br />
        Your information, including Personal Data, may be transferred to - and
        maintained on - computers <br />
        located outside of your state, province, country or other governmental{" "}
        <br />
        jurisdiction where the data protection laws may differ from those of
        your jurisdiction.
        <br />
        <br />
        If you are located outside Germany and choose to provide information to
        us, please note that we <br />
        transfer the data, including Personal Data, to Germany and
        <br />
        process it there.
        <br />
        <br />
        Your consent to this Privacy Policy followed by your submission of such
        information represents <br />
        your agreement to that transfer.
        <br />
        <br />
        Lucas Goldner will take all the steps reasonably necessary to ensure
        that your data is <br />
        treated securely and in accordance with this Privacy Policy and no
        transfer of your Personal <br />
        Data will take place to an organization or a country unless there are
        adequate controls in place <br />
        including the security of your data and other personal information.
        <br />
        <br />
        Disclosure of Data <br />
        ------------------
        <br />
        <br />
        Legal Requirements <br />
        ~~~~~~~~~~~~~~~~~~
        <br />
        <br />
        Lucas Goldner may disclose your Personal Data in the good faith belief
        that such action <br />
        is necessary to:
        <br />
        <br />
        * To comply with a legal obligation
        <br />
        * To protect and defend the rights or property of Swoboda
        <br />
        * To prevent or investigate possible wrongdoing in connection with the
        Service
        <br />
        * To protect the personal safety of users of the Service or the public
        <br />
        * To protect against legal liability
        <br />
        <br />
        Security of Data <br />
        ----------------
        <br />
        <br />
        The security of your data is important to us but remember that no method
        of transmission over <br />
        the Internet or method of electronic storage is 100% secure. While we
        strive to <br />
        use commercially acceptable means to protect your Personal Data, we
        cannot guarantee its <br />
        absolute security.
        <br />
        <br />
        Your Data Protection Rights under the General Data Protection Regulation
        (GDPR) <br />
        -------------------------------------------------------------------------------
        <br />
        <br />
        If you are a resident of the European Economic Area (EEA), you have
        certain data protection <br />
        rights. Lucas Goldner aims to take reasonable steps to allow you to
        correct, amend, <br />
        delete or limit the use of your Personal Data.
        <br />
        <br />
        If you wish to be informed about what Personal Data we hold about you
        and if you want it to be <br />
        removed from our systems, please contact us.
        <br />
        <br />
        In certain circumstances, you have the following data protection rights:
        <br />
        <br />
        * The right to access, update or delete the information we have on you.
        Whenever made possible, <br />
        you can access, update or request deletion of your
        <br />
        Personal Data directly within your account settings section. If you are
        unable to perform these <br />
        actions yourself, please contact us to assist you.
        <br />
        <br />
        * The right of rectification. You have the right to have your
        information rectified if that information <br />
        is inaccurate or incomplete.
        <br />
        <br />
        * The right to object. You have the right to object to our processing of
        your Personal Data.
        <br />
        <br />
        * The right of restriction. You have the right to request that we
        restrict the processing of your <br />
        personal information.
        <br />
        <br />
        * The right to data portability. You have the right to be provided with
        a copy of the information we <br />
        have on you in a structured, machine-readable and commonly used <br />
        format.
        <br />
        <br />
        * The right to withdraw consent. You also have the right to withdraw
        your consent at any time
        <br />
        where Lucas Goldner relied on your consent to process your personal
        information.
        <br />
        <br />
        <br />
        Please note that we may ask you to verify your identity before
        responding to such requests.
        <br />
        <br />
        You have the right to complain to a Data Protection Authority about our
        collection and use of your <br />
        Personal Data. For more information, please contact your local data{" "}
        <br />
        protection authority in the European Economic Area (EEA).
        <br />
        Service Providers <br />
        -----------------
        <br />
        We may employ third party companies and individuals to facilitate our
        Service ("Service <br />
        Providers"), provide the Service on our behalf, perform Service-related
        services or <br />
        assist us in analyzing how our Service is used.
        <br />
        <br />
        These third parties have access to your Personal Data only to perform
        these tasks on our behalf <br />
        and are obligated not to disclose or use it for any other purpose.
        <br />
        <br />
        Links to Other Sites <br />
        --------------------
        <br />
        <br />
        Our Service may contain links to other sites that are not operated by
        us. If you click a third party <br />
        link, you will be directed to that third party's site. We strongly
        advise you to <br />
        review the Privacy Policy of every site you visit.
        <br />
        <br />
        We have no control over and assume no responsibility for the content,
        privacy policies or <br />
        practices of any third party sites or services.
        <br />
        <br />
        Children's Privacy <br />
        ------------------
        <br />
        <br />
        Our Service does not address anyone under the age of 18 (“Children").
        <br />
        <br />
        We do not knowingly collect personally identifiable information from
        anyone under the age of 18. <br />
        If you are a parent or guardian and you are aware that your Child has{" "}
        <br />
        provided us with Personal Data, please contact us. If we become aware
        that we have collected <br />
        Personal Data from children without verification of parental consent, we
        take <br />
        steps to remove that information from our servers.
        <br />
        <br />
        Changes to This Privacy Policy <br />
        ------------------------------
        <br />
        <br />
        We may update our Privacy Policy from time to time. We will notify you
        of any changes by posting <br />
        the new Privacy Policy on this page.
        <br />
        <br />
        We will let you know via email and/or a prominent notice on our Service,
        prior to the change <br />
        becoming effective and update the "effective date" at the top of this
        Privacy <br />
        Policy.
        <br />
        <br />
        You are advised to review this Privacy Policy periodically for any
        changes. Changes to this Privacy <br />
        Policy are effective when they are posted on this page.
        <br />
        <br />
        Contact Us
        <br />
        ----------
        <br />
        <br />
        If you have any questions about this Privacy Policy, please contact us:
        <br />
        <br />* By email: lucas.goldner@googlemail.com
      </div>
    );
  }
}

export default TosPage;
